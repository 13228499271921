import React from 'react';
import { useAuth } from '../../hooks/useAuth';
import { LoginPrompt } from './LoginPrompt';
import { ApplicationForm } from './ApplicationForm';

export function RecruitmentForm() {
  const { isAuthenticated, user, isLoading } = useAuth();

  if (isLoading) {
    return (
      <div className="flex justify-center items-center p-8">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-gold-500"></div>
      </div>
    );
  }

  if (!isAuthenticated || !user) {
    return <LoginPrompt />;
  }

  return <ApplicationForm user={user} />;
}
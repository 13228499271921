import React from 'react';
import logo from '../../assets/logo.png';

interface LogoProps {
  className?: string;
  size?: 'sm' | 'md' | 'lg' | 'xl';
}

const sizes = {
  sm: 'w-8 h-8',
  md: 'w-12 h-12',
  lg: 'w-16 h-16',
  xl: 'w-24 h-24'
};

export function Logo({ className = '', size = 'md' }: LogoProps) {
  return (
    <img
      src={logo}
      alt="DPD Logo"
      className={`${sizes[size]} ${className}`}
    />
  );
}
import React from 'react';
import { Shield } from 'lucide-react';
import { RecruitmentForm } from '../components/recruitment/RecruitmentForm';

export function Recruitment() {
  return (
    <div className="container mx-auto px-4 py-12">
      <div className="text-center mb-12">
        <Shield className="w-12 h-12 text-gold-500 mx-auto mb-4" />
        <h1 className="text-3xl md:text-4xl font-bold mb-4">Rejoignez le DPD</h1>
        <p className="text-gray-400 max-w-2xl mx-auto">
          Commencez votre carrière au sein de l'élite du District. Remplissez le formulaire de candidature ci-dessous pour débuter votre carrière dans les forces de l'ordre.
        </p>
      </div>

      <div className="max-w-2xl mx-auto">
        <RecruitmentForm />
      </div>
    </div>
  );
}
import { useState, useEffect } from 'react';
import { DISCORD_CONFIG } from '../config/discord';
import { generateApplicationId } from '../utils/generateApplicationId';
import { calculateReviewDate } from '../utils/dates';
import type { User } from '../types/auth';
import type { ApplicationStatus, ApplicationFormData } from '../types/application';

export function useApplicationForm(formData: ApplicationFormData, user: User) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [applicationStatus, setApplicationStatus] = useState<ApplicationStatus | null>(null);

  // Vérifie si l'utilisateur a déjà une candidature
  useEffect(() => {
    const checkExistingApplication = async () => {
      try {
        const response = await fetch(`/api/applications/${user.id}`);
        const data = await response.json();
        if (data?.submitted) {
          // Si la candidature est rejetée, vérifie le délai de 30 jours
          if (data.status === 'rejected') {
            const rejectionDate = new Date(data.rejectionDate);
            const now = new Date();
            const daysSinceRejection = Math.floor((now.getTime() - rejectionDate.getTime()) / (1000 * 60 * 60 * 24));
            
            if (daysSinceRejection < 30) {
              data.daysRemaining = 30 - daysSinceRejection;
              setApplicationStatus(data);
              return;
            }
          } else if (data.status !== 'rejected') {
            setApplicationStatus(data);
          }
        }
      } catch (error) {
        console.error('Erreur lors de la vérification de la candidature:', error);
      }
    };

    checkExistingApplication();
  }, [user.id]);

  // Polling pour les mises à jour du statut
  useEffect(() => {
    const fetchStatus = async () => {
      try {
        const response = await fetch(`/api/applications/${user.id}`);
        const data = await response.json();
        if (data) {
          setApplicationStatus(data);
        }
      } catch (error) {
        console.error('Erreur lors de la récupération du statut:', error);
      }
    };

    if (applicationStatus?.submitted && applicationStatus?.status === 'pending') {
      const interval = setInterval(fetchStatus, 5000);
      return () => clearInterval(interval);
    }
  }, [user.id, applicationStatus?.submitted, applicationStatus?.status]);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    
    // Vérifie si l'utilisateur peut soumettre une nouvelle candidature
    if (applicationStatus?.submitted) {
      if (applicationStatus.status === 'rejected' && applicationStatus.daysRemaining) {
        setError(`Vous devez attendre encore ${applicationStatus.daysRemaining} jours avant de pouvoir soumettre une nouvelle candidature.`);
        return;
      } else if (applicationStatus.status !== 'rejected') {
        setError('Vous avez déjà une candidature en cours.');
        return;
      }
    }

    setIsSubmitting(true);
    setError(null);

    try {
      const reviewDate = calculateReviewDate();
      const applicationId = generateApplicationId();

      const status: ApplicationStatus = {
        submitted: true,
        reviewDate,
        applicationId,
        userId: user.id,
        status: 'pending'
      };

      const response = await fetch('/api/applications', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          key: `application_status_${user.id}`,
          data: status
        })
      });

      if (!response.ok) {
        throw new Error('Erreur lors de la sauvegarde de la candidature');
      }

      const embed = {
        title: "📋 Nouvelle Candidature DPD",
        description: "Une nouvelle candidature a été soumise pour rejoindre le DPD.",
        color: 0xFFD700,
        fields: [
          {
            name: "📌 Informations de la candidature",
            value: [
              `**ID:** ${applicationId}`,
              `**Date de revue:** ${reviewDate}`,
            ].join('\n'),
            inline: false
          },
          {
            name: "👤 Profil du candidat",
            value: [
              `**Nom:** ${formData.fullName}`,
              `**Âge:** ${formData.age} ans`,
              `**Discord:** <@${user.id}>`,
            ].join('\n'),
            inline: false
          },
          {
            name: "📚 Formation & Expérience",
            value: [
              `**Parcours:** ${formData.background}`,
              `**Service Militaire:** ${formData.militaryService}`,
              `**Expérience Forces de l'Ordre:** ${formData.lawEnforcementExp}`,
            ].join('\n\n'),
            inline: false
          },
          {
            name: "🏥 Profil médical & judiciaire",
            value: [
              `**Condition Physique:** ${formData.physicalCondition}`,
              `**Antécédents:** ${formData.criminalRecord}`,
            ].join('\n\n'),
            inline: false
          },
          {
            name: "💭 Motivation",
            value: formData.motivation,
            inline: false
          },
          {
            name: "📝 Scénarios d'évaluation",
            value: "Voir les réponses détaillées ci-dessous ⬇️",
            inline: false
          },
          {
            name: "🤔 Scénario Éthique - Corruption",
            value: formData.ethicsScenario,
            inline: false
          },
          {
            name: "⚡ Scénario Stress - Situation de crise",
            value: formData.stressScenario,
            inline: false
          }
        ],
        footer: {
          text: "DPD - Système de recrutement"
        },
        timestamp: new Date().toISOString()
      };

      const webhookResponse = await fetch(DISCORD_CONFIG.webhookUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ embeds: [embed] })
      });

      if (!webhookResponse.ok) {
        throw new Error('Erreur lors de l\'envoi de la notification Discord');
      }

      setApplicationStatus(status);
    } catch (err) {
      setError('Une erreur est survenue lors de l\'envoi de votre candidature. Veuillez réessayer.');
      console.error('Erreur:', err);
    } finally {
      setIsSubmitting(false);
    }
  };

  return {
    handleSubmit,
    isSubmitting,
    error,
    applicationStatus
  };
}
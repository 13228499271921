export const SCENARIOS = {
  ethics: {
    title: "Scénario éthique",
    description: `Lors d'une patrouille de routine, vous surprenez un collègue officier en train d'accepter de l'argent d'un civil. 
    En vous approchant, vous entendez clairement qu'il s'agit d'un pot-de-vin en échange de la non-verbalisation d'une infraction grave au code de la route.
    
    Comment gérez-vous cette situation ? Détaillez votre raisonnement et les actions que vous entreprendriez.`,
  },
  stress: {
    title: "Gestion du stress",
    description: `Vous patrouiller dans un quartier animé de Manhattan quand vous recevez un appel concernant un homme armé menaçant des civils.
    En arrivant sur place, vous constatez que le suspect est instable et pointe son arme vers la foule.
    
    Comment gérez-vous cette situation de crise ? Quelles sont vos priorités et actions immédiates ?`,
  }
};
import React from 'react';
import type { Division } from '../data/divisions';
import { Shield, Star } from 'lucide-react';

interface DivisionCardProps {
  division: Division;
}

export function DivisionCard({ division }: DivisionCardProps) {
  return (
    <div className="bg-navy-800 rounded-lg overflow-hidden border border-gold-500/20 hover:border-gold-500/40 transition-colors">
      <div className="p-6">
        <div className="flex items-center gap-4 mb-4">
          <div className="p-3 bg-navy-900 rounded-lg">
            <Shield className="w-6 h-6 text-gold-500" />
          </div>
          <h3 className="text-xl font-bold">{division.name}</h3>
        </div>
        <p className="text-gray-400 mb-6">{division.description}</p>
        
        <div className="flex items-center gap-2 mb-6 bg-navy-900/50 p-3 rounded-lg">
          <Star className="w-5 h-5 text-gold-500" />
          <span className="text-sm text-gold-500">Grade minimum requis : {division.minimumRank}</span>
        </div>

        <div className="space-y-4">
          <h4 className="font-semibold text-gold-500">Responsabilités principales :</h4>
          <ul className="space-y-2">
            {division.responsibilities.map((responsibility, index) => (
              <li key={index} className="flex items-start gap-2">
                <span className="text-gold-500 mt-1.5">•</span>
                <span className="text-gray-300">{responsibility}</span>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}
import React from 'react';
import { Shield, ChevronRight, Star } from 'lucide-react';
import type { Rank } from '../../data/ranks';
import { cn } from '../../lib/utils';

interface RankCardProps {
  rank: Rank;
  isExpanded: boolean;
  onToggle: () => void;
}

export function RankCard({ rank, isExpanded, onToggle }: RankCardProps) {
  return (
    <div className={cn(
      "bg-navy-800 rounded-lg border overflow-hidden",
      rank.specialization ? "border-gold-500/40" : "border-gold-500/20"
    )}>
      <button
        onClick={onToggle}
        className="w-full p-4 flex items-center justify-between hover:bg-navy-900/50 transition-colors"
      >
        <div className="flex items-center gap-3">
          {rank.specialization ? (
            <Star className="w-5 h-5 text-gold-500" />
          ) : (
            <Shield className="w-5 h-5 text-gold-500" />
          )}
          <h3 className="font-semibold">{rank.title}</h3>
          {rank.specialization && (
            <span className="text-xs bg-gold-500/10 text-gold-500 px-2 py-1 rounded">
              Spécialisation
            </span>
          )}
        </div>
        <ChevronRight className={`w-5 h-5 text-gold-500 transition-transform ${isExpanded ? 'rotate-90' : ''}`} />
      </button>

      {isExpanded && (
        <div className="p-4 border-t border-gold-500/20">
          <p className="text-gray-300 mb-4">{rank.description}</p>

          <div className="space-y-4">
            <div>
              <h4 className="text-gold-500 font-medium mb-2">Responsabilités :</h4>
              <ul className="space-y-1">
                {rank.responsibilities.map((resp, index) => (
                  <li key={index} className="text-gray-300 flex items-start gap-2">
                    <span className="text-gold-500 mt-1">•</span>
                    <span>{resp}</span>
                  </li>
                ))}
              </ul>
            </div>

            {rank.requirements && (
              <div>
                <h4 className="text-gold-500 font-medium mb-2">Prérequis :</h4>
                <ul className="space-y-1">
                  {rank.requirements.map((req, index) => (
                    <li key={index} className="text-gray-300 flex items-start gap-2">
                      <span className="text-gold-500 mt-1">•</span>
                      <span>{req}</span>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
export const NYPD_RULES = {
  oath: {
    title: "Serment de l'Officier",
    rules: [
      "Je jure solennellement de servir et de protéger la ville de New York et ses citoyens",
      "De faire respecter la loi avec honneur, intégrité et dévouement",
      "De défendre les droits constitutionnels de tous",
      "De remplir mes fonctions avec courage et abnégation",
      "De maintenir la confiance qui m'est accordée en tant qu'officier de police"
    ]
  },
  values: {
    title: "Valeurs Fondamentales",
    rules: [
      "Courage : Faire face au danger avec détermination et professionnalisme",
      "Intégrité : Maintenir les plus hauts standards d'honnêteté et d'éthique",
      "Professionnalisme : Représenter le département avec dignité et compétence",
      "Respect : Traiter tous les citoyens avec dignité, quelle que soit leur origine",
      "Excellence : Viser constamment l'amélioration de nos services"
    ]
  },
  discipline: {
    title: "Discipline et Comportement",
    rules: [
      "Porter l'uniforme avec fierté et maintenir une apparence irréprochable",
      "Respecter la chaîne de commandement et les ordres des supérieurs",
      "S'abstenir de tout comportement pouvant nuire à l'image du département",
      "Maintenir une condition physique appropriée aux exigences du service",
      "Faire preuve de discrétion concernant les affaires du département"
    ]
  },
  operations: {
    title: "Procédures Opérationnelles",
    rules: [
      "Utiliser la force uniquement lorsque nécessaire et de manière proportionnée",
      "Protéger les scènes de crime et préserver les preuves",
      "Répondre rapidement aux appels d'urgence selon leur priorité",
      "Maintenir une communication claire et professionnelle sur les ondes radio",
      "Rédiger des rapports détaillés et précis après chaque intervention"
    ]
  },
  community: {
    title: "Relations Communautaires",
    rules: [
      "Développer et maintenir des relations positives avec la communauté",
      "Participer activement aux programmes de prévention et d'engagement communautaire",
      "Être à l'écoute des préoccupations des citoyens",
      "Représenter le département de manière professionnelle lors des interactions publiques",
      "Promouvoir la confiance entre la police et la communauté"
    ]
  },
  equipment: {
    title: "Équipement et Ressources",
    rules: [
      "Maintenir son arme de service en parfait état de fonctionnement",
      "Inspecter quotidiennement son véhicule de patrouille",
      "Utiliser les équipements de protection lors des interventions à risque",
      "Prendre soin du matériel confié par le département",
      "Signaler immédiatement tout équipement défectueux"
    ]
  }
};
import React from 'react';
import { Shield } from 'lucide-react';
import { divisions } from '../data/divisions';
import { DivisionCard } from '../components/DivisionCard';

export function Divisions() {
  return (
    <div className="container mx-auto px-4 py-12">
      <div className="text-center mb-12">
        <Shield className="w-12 h-12 text-gold-500 mx-auto mb-4" />
        <h1 className="text-3xl md:text-4xl font-bold mb-4">Divisions du DPD</h1>
        <p className="text-gray-400 max-w-2xl mx-auto">
          Découvrez les unités spécialisées et les bureaux qui travaillent ensemble pour assurer la sécurité de New York.
        </p>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {divisions.map((division) => (
          <DivisionCard key={division.id} division={division} />
        ))}
      </div>
    </div>
  );
}
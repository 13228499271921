import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Layout } from './components/Layout';
import { Home } from './pages/Home';
import { Divisions } from './pages/Divisions';
import { Recruitment } from './pages/Recruitment';
import { Rules } from './pages/Rules';
import { Hierarchy } from './pages/Hierarchy';

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="/divisions" element={<Divisions />} />
          <Route path="/recruitment" element={<Recruitment />} />
          <Route path="/rules" element={<Rules />} />
          <Route path="/hierarchy" element={<Hierarchy />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
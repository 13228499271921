import React, { useState } from 'react';
import { useApplicationForm } from '../../hooks/useApplicationForm';
import { SCENARIOS } from '../../data/scenarios';
import type { User } from '../../types/auth';
import { ApplicationStatus } from './ApplicationStatus';
import type { ApplicationFormData } from '../../types/application';

interface ApplicationFormProps {
  user: User;
}

export function ApplicationForm({ user }: ApplicationFormProps) {
  const [formData, setFormData] = useState<ApplicationFormData>({
    fullName: '',
    age: '',
    background: '',
    criminalRecord: '',
    physicalCondition: '',
    militaryService: '',
    lawEnforcementExp: '',
    motivation: '',
    ethicsScenario: '',
    stressScenario: '',
    rules: false
  });

  const { handleSubmit, isSubmitting, error, applicationStatus } = useApplicationForm(formData, user);

  if (applicationStatus?.submitted) {
    return (
      <ApplicationStatus 
        reviewDate={applicationStatus.reviewDate} 
        applicationId={applicationStatus.applicationId}
        status={applicationStatus.status}
      />
    );
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = e.target.type === 'checkbox' ? (e.target as HTMLInputElement).checked : e.target.value;
    setFormData(prev => ({
      ...prev,
      [e.target.name]: value
    }));
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      <div className="bg-navy-800 rounded-lg p-6 border border-gold-500/20">
        <h3 className="text-xl font-semibold mb-4">Formulaire de Recrutement - District Police Department</h3>
        
        <div className="space-y-4">
          <div>
            <label htmlFor="fullName" className="block text-sm font-medium text-gray-200 mb-1">
              Nom complet du candidat
            </label>
            <input
              type="text"
              id="fullName"
              name="fullName"
              value={formData.fullName}
              onChange={handleChange}
              className="w-full bg-navy-900 border border-gold-500/20 rounded-lg px-4 py-2 text-gray-100"
              required
              placeholder="John Doe"
            />
          </div>

          <div>
            <label htmlFor="age" className="block text-sm font-medium text-gray-200 mb-1">
              Âge du candidat
            </label>
            <input
              type="number"
              id="age"
              name="age"
              value={formData.age}
              onChange={handleChange}
              className="w-full bg-navy-900 border border-gold-500/20 rounded-lg px-4 py-2 text-gray-100"
              required
              min="21"
              max="35"
            />
            <p className="text-xs text-gray-400 mt-1">Les candidats doivent avoir entre 21 et 35 ans</p>
          </div>

          <div>
            <label htmlFor="background" className="block text-sm font-medium text-gray-200 mb-1">
              Parcours personnel et professionnel
            </label>
            <textarea
              id="background"
              name="background"
              value={formData.background}
              onChange={handleChange}
              className="w-full bg-navy-900 border border-gold-500/20 rounded-lg px-4 py-2 text-gray-100"
              rows={4}
              required
              placeholder="Décrivez votre parcours, votre éducation et vos expériences professionnelles..."
            />
          </div>

          <div>
            <label htmlFor="criminalRecord" className="block text-sm font-medium text-gray-200 mb-1">
              Antécédents judiciaires
            </label>
            <textarea
              id="criminalRecord"
              name="criminalRecord"
              value={formData.criminalRecord}
              onChange={handleChange}
              className="w-full bg-navy-900 border border-gold-500/20 rounded-lg px-4 py-2 text-gray-100"
              rows={2}
              required
              placeholder="Avez-vous déjà été arrêté ou condamné ? Si oui, précisez..."
            />
          </div>

          <div>
            <label htmlFor="physicalCondition" className="block text-sm font-medium text-gray-200 mb-1">
              Condition physique et état de santé
            </label>
            <textarea
              id="physicalCondition"
              name="physicalCondition"
              value={formData.physicalCondition}
              onChange={handleChange}
              className="w-full bg-navy-900 border border-gold-500/20 rounded-lg px-4 py-2 text-gray-100"
              rows={2}
              required
              placeholder="Décrivez votre condition physique, sports pratiqués, limitations éventuelles..."
            />
          </div>

          <div>
            <label htmlFor="militaryService" className="block text-sm font-medium text-gray-200 mb-1">
              Service militaire
            </label>
            <textarea
              id="militaryService"
              name="militaryService"
              value={formData.militaryService}
              onChange={handleChange}
              className="w-full bg-navy-900 border border-gold-500/20 rounded-lg px-4 py-2 text-gray-100"
              rows={2}
              required
              placeholder="Avez-vous servi dans l'armée ? Si oui, précisez votre expérience..."
            />
          </div>

          <div>
            <label htmlFor="lawEnforcementExp" className="block text-sm font-medium text-gray-200 mb-1">
              Expérience dans les forces de l'ordre
            </label>
            <textarea
              id="lawEnforcementExp"
              name="lawEnforcementExp"
              value={formData.lawEnforcementExp}
              onChange={handleChange}
              className="w-full bg-navy-900 border border-gold-500/20 rounded-lg px-4 py-2 text-gray-100"
              rows={3}
              required
              placeholder="Avez-vous déjà travaillé dans les forces de l'ordre ? Détaillez votre expérience..."
            />
          </div>

          <div>
            <label htmlFor="motivation" className="block text-sm font-medium text-gray-200 mb-1">
              Motivation pour rejoindre le DPD
            </label>
            <textarea
              id="motivation"
              name="motivation"
              value={formData.motivation}
              onChange={handleChange}
              className="w-full bg-navy-900 border border-gold-500/20 rounded-lg px-4 py-2 text-gray-100"
              rows={4}
              required
              placeholder="Pourquoi souhaitez-vous devenir officier du DPD ? Quelles sont vos motivations ?"
            />
          </div>

          <div>
            <label htmlFor="ethicsScenario" className="block text-sm font-medium text-gray-200 mb-1">
              {SCENARIOS.ethics.title}
            </label>
            <div className="bg-navy-900 p-4 rounded-lg mb-2 text-gray-300 text-sm">
              {SCENARIOS.ethics.description}
            </div>
            <textarea
              id="ethicsScenario"
              name="ethicsScenario"
              value={formData.ethicsScenario}
              onChange={handleChange}
              className="w-full bg-navy-900 border border-gold-500/20 rounded-lg px-4 py-2 text-gray-100"
              rows={6}
              required
              placeholder="Détaillez votre réponse..."
            />
          </div>

          <div>
            <label htmlFor="stressScenario" className="block text-sm font-medium text-gray-200 mb-1">
              {SCENARIOS.stress.title}
            </label>
            <div className="bg-navy-900 p-4 rounded-lg mb-2 text-gray-300 text-sm">
              {SCENARIOS.stress.description}
            </div>
            <textarea
              id="stressScenario"
              name="stressScenario"
              value={formData.stressScenario}
              onChange={handleChange}
              className="w-full bg-navy-900 border border-gold-500/20 rounded-lg px-4 py-2 text-gray-100"
              rows={6}
              required
              placeholder="Détaillez votre réponse..."
            />
          </div>

          <div className="flex items-center space-x-2">
            <input
              type="checkbox"
              id="rules"
              name="rules"
              checked={formData.rules}
              onChange={handleChange}
              className="h-4 w-4 text-gold-500 border-gold-500/20 rounded"
              required
            />
            <label htmlFor="rules" className="text-sm text-gray-200">
              Je certifie sur l'honneur l'exactitude des informations fournies et accepte le règlement du DPD
            </label>
          </div>
        </div>
      </div>

      {error && (
        <div className="bg-red-900/50 border border-red-500/50 text-red-200 p-4 rounded-lg">
          {error}
        </div>
      )}

      <button
        type="submit"
        disabled={isSubmitting || !formData.rules}
        className="w-full bg-gold-500 hover:bg-gold-400 text-navy-900 font-semibold py-3 px-6 rounded-lg transition-colors disabled:opacity-50"
      >
        {isSubmitting ? 'Envoi en cours...' : 'Soumettre ma candidature'}
      </button>
    </form>
  );
}
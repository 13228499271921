import React from 'react';
import { CheckCircle, Calendar, Hash, XCircle } from 'lucide-react';

interface ApplicationStatusProps {
  reviewDate: string;
  applicationId: string;
  status?: 'pending' | 'accepted' | 'rejected';
}

export function ApplicationStatus({ reviewDate, applicationId, status = 'pending' }: ApplicationStatusProps) {
  const getStatusContent = () => {
    switch (status) {
      case 'accepted':
        return {
          icon: <CheckCircle className="w-16 h-16 text-green-500 mb-4" />,
          title: 'Candidature Acceptée',
          message: 'Félicitations ! Votre candidature a été acceptée.',
          color: 'text-green-500'
        };
      case 'rejected':
        return {
          icon: <XCircle className="w-16 h-16 text-red-500 mb-4" />,
          title: 'Candidature Refusée',
          message: 'Nous sommes désolés, votre candidature n\'a pas été retenue.',
          color: 'text-red-500'
        };
      default:
        return {
          icon: <CheckCircle className="w-16 h-16 text-gold-500 mb-4" />,
          title: 'Candidature Envoyée',
          message: 'Votre candidature est en cours d\'examen.',
          color: 'text-gold-500'
        };
    }
  };

  const statusContent = getStatusContent();

  return (
    <div className="bg-navy-800 rounded-lg p-8 border border-gold-500/20">
      <div className="flex flex-col items-center text-center">
        {statusContent.icon}
        <h2 className={`text-2xl font-bold mb-4 ${statusContent.color}`}>
          {statusContent.title}
        </h2>
        
        <div className="bg-navy-900/50 rounded-lg p-6 mb-6 w-full">
          <div className="flex items-center justify-center space-x-3 mb-4">
            <Hash className="w-6 h-6 text-gold-500" />
            <span className="text-lg font-semibold">ID de Candidature</span>
          </div>
          <p className="text-gold-500 text-xl font-mono">{applicationId}</p>
        </div>

        <div className="bg-navy-900/50 rounded-lg p-6 mb-6 w-full">
          <div className="flex items-center justify-center space-x-3 mb-4">
            <Calendar className="w-6 h-6 text-gold-500" />
            <span className="text-lg font-semibold">Date de revue prévue</span>
          </div>
          <p className="text-gold-500">{reviewDate}</p>
        </div>

        <div className="space-y-4 text-gray-300">
          <p>{statusContent.message}</p>
          {status === 'pending' && (
            <>
              <p>Un instructeur examinera votre dossier dans un délai de 72 heures.</p>
              <p>Si votre candidature est retenue, vous serez contacté via Discord pour planifier un entretien.</p>
            </>
          )}
          {status === 'accepted' && (
            <p>Un officier vous contactera prochainement pour organiser votre intégration.</p>
          )}
          {status === 'rejected' && (
            <p>Vous pourrez soumettre une nouvelle candidature dans 30 jours.</p>
          )}
        </div>

        <div className="mt-8 p-4 bg-navy-900/30 rounded-lg text-sm text-gray-400">
          Note: Conservez votre ID de candidature pour toute communication future.
        </div>
      </div>
    </div>
  );
}
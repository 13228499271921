import { getDivisionImage } from '../utils/divisionImages';

export interface Division {
  id: string;
  name: string;
  description: string;
  responsibilities: string[];
  minimumRank: string;
}

export const divisions: Division[] = [
  {
    id: 'highway_patrol',
    name: 'Highway Patrol',
    description: 'La Highway Patrol est spécialisée dans la sécurité des routes et autoroutes, assurant une vigilance constante et intervenant rapidement lors d\'urgences ou d\'incidents routiers.',
    responsibilities: [
      'Faire respecter les lois sur la circulation routière',
      'Assister les automobilistes en difficulté',
      'Mener des enquêtes sur les accidents de la route',
      'Intervenir lors de situations d\'urgence comme des catastrophes naturelles ou des attaques terroristes'
    ],
    minimumRank: 'Officier II'
  },
  {
    id: 'esu',
    name: 'Emergency Service Unit',
    description: 'Le DPD Emergency Service Unit est une unité d\'élite spécialisée dans les interventions complexes, les sauvetages et les situations d\'urgence nécessitant un équipement et des compétences techniques avancés.',
    responsibilities: [
      'Intervenir face à des individus armés ou dangereux',
      'Réaliser des sauvetages en milieu difficile',
      'Participer aux arrestations de suspects à haut risque',
      'Fournir une assistance technique et tactique lors d\'opérations complexes'
    ],
    minimumRank: 'Officier II'
  },
  {
    id: 'aviation_unit',
    name: 'Aviation Unit',
    description: 'L\'Aviation Unit fournit un support aérien essentiel aux équipes au sol, permettant des interventions rapides et une surveillance accrue.',
    responsibilities: [
      'Fournir un support visuel et de surveillance aérienne aux équipes au sol',
      'Déployer des unités dans des environnements difficiles d\'accès',
      'Assurer la sécurité aérienne lors des opérations critiques',
      'Participer à des missions de recherche et de sauvetage'
    ],
    minimumRank: 'Officier II'
  },
  {
    id: 'k9_unit',
    name: 'K-9 Unit',
    description: 'La K-9 Unit est une unité spécialisée utilisant des chiens dressés pour des missions de lutte anti-drogue, de recherche et de détection d\'explosifs.',
    responsibilities: [
      'Lutte contre les stupéfiants grâce à la détection canine',
      'Recherche de corps ou d\'individus disparus',
      'Détection de poudre et d\'explosifs',
      'Soutien aux opérations tactiques grâce à des chiens hautement entraînés'
    ],
    minimumRank: 'Officier II'
  },
  {
    id: 'detective_division',
    name: 'Detective Division',
    description: 'La Detective Division est chargée de mener des enquêtes approfondies sur les crimes graves, en collectant des preuves et en identifiant les suspects.',
    responsibilities: [
      'Enquêter sur les crimes graves tels que les homicides, les cambriolages et les fraudes',
      'Collecter et analyser des preuves pour résoudre des affaires',
      'Interroger les témoins et les suspects dans le cadre des enquêtes',
      'Travailler en étroite collaboration avec d\'autres agences pour élucider les affaires complexes'
    ],
    minimumRank: 'Officier II'
  }
];
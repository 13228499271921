import React from 'react';
import { Link, Outlet } from 'react-router-dom';
import { Menu, X } from 'lucide-react';
import { cn } from '../lib/utils';
import { Disclaimer } from './Disclaimer';
import { Logo } from './common/Logo';

export function Layout() {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  return (
    <div className="min-h-screen bg-navy-900 text-gray-100">
      <Disclaimer />
      <header className="bg-navy-800 border-b border-gold-500/20">
        <nav className="container mx-auto px-4 py-4">
          <div className="flex items-center justify-between">
            <Link to="/" className="flex items-center space-x-3">
              <Logo size="sm" />
              <span className="text-xl font-bold">DPD</span>
            </Link>
            
            <button
              onClick={toggleMenu}
              className="lg:hidden text-gray-100 hover:text-gold-500"
            >
              {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
            </button>

            <div className={cn(
              "fixed inset-0 z-50 bg-navy-900 lg:static lg:bg-transparent lg:flex",
              "transform transition-transform duration-200 ease-in-out",
              "lg:translate-x-0",
              isMenuOpen ? "translate-x-0" : "translate-x-full",
              "lg:items-center"
            )}>
              <div className="flex flex-col lg:flex-row lg:items-center space-y-4 lg:space-y-0 lg:space-x-8 p-8 lg:p-0">
                <Link 
                  to="/"
                  className="hover:text-gold-500 transition-colors"
                  onClick={() => setIsMenuOpen(false)}
                >
                  Accueil
                </Link>
                <Link 
                  to="/divisions"
                  className="hover:text-gold-500 transition-colors"
                  onClick={() => setIsMenuOpen(false)}
                >
                  Divisions
                </Link>
                <Link 
                  to="/hierarchy"
                  className="hover:text-gold-500 transition-colors"
                  onClick={() => setIsMenuOpen(false)}
                >
                  Hiérarchie
                </Link>
                <Link 
                  to="/rules"
                  className="hover:text-gold-500 transition-colors"
                  onClick={() => setIsMenuOpen(false)}
                >
                  Règlement
                </Link>
                <Link 
                  to="/recruitment"
                  className="hover:text-gold-500 transition-colors"
                  onClick={() => setIsMenuOpen(false)}
                >
                  Recrutement
                </Link>
              </div>
            </div>
          </div>
        </nav>
      </header>

      <main>
        <Outlet />
      </main>

      <footer className="bg-navy-800 border-t border-gold-500/20 py-8 mt-16">
        <div className="container mx-auto px-4">
          <div className="flex flex-col md:flex-row justify-between items-center">
            <div className="flex items-center space-x-3 mb-4 md:mb-0">
              <Logo size="sm" />
              <span className="text-lg font-bold">DPD</span>
            </div>
            <div className="text-sm text-gray-400">
              © {new Date().getFullYear()} District Police Department. Tous droits réservés.
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}
import React, { useState } from 'react';
import { Shield } from 'lucide-react';
import { ranks } from '../data/ranks';
import { RankCard } from '../components/hierarchy/RankCard';

export function Hierarchy() {
  const [expandedRank, setExpandedRank] = useState<string | null>(null);

  const handleToggle = (rankId: string) => {
    setExpandedRank(expandedRank === rankId ? null : rankId);
  };

  return (
    <div className="container mx-auto px-4 py-12">
      <div className="text-center mb-12">
        <Shield className="w-12 h-12 text-gold-500 mx-auto mb-4" />
        <h1 className="text-3xl md:text-4xl font-bold mb-4">Hiérarchie du DPD</h1>
        <p className="text-gray-400 max-w-2xl mx-auto">
          Découvrez les différents grades et leurs responsabilités au sein du département de District Police Department.
        </p>
      </div>

      <div className="max-w-3xl mx-auto space-y-4">
        {Object.values(ranks).map((rank) => (
          <RankCard
            key={rank.id}
            rank={rank}
            isExpanded={expandedRank === rank.id}
            onToggle={() => handleToggle(rank.id)}
          />
        ))}
      </div>

      <div className="mt-12 bg-navy-800 rounded-lg p-6 max-w-3xl mx-auto border border-gold-500/20">
        <h2 className="text-xl font-semibold text-gold-500 mb-4">Progression de carrière</h2>
        <p className="text-gray-300 mb-4">
          La progression au sein du DPD est basée sur le mérite, l'expérience et la formation continue. 
          Chaque grade offre de nouvelles opportunités et responsabilités, permettant aux officiers de 
          se spécialiser et d'évoluer dans leur carrière.
        </p>
        <p className="text-gray-300">
          Les promotions sont accordées sur la base des performances, de l'ancienneté et de la réussite 
          aux examens spécifiques à chaque grade.
        </p>
      </div>
    </div>
  );
}
export function calculateReviewDate(): string {
  const date = new Date();
  date.setHours(date.getHours() + 72); // Ajoute 72 heures
  
  const options: Intl.DateTimeFormatOptions = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  };

  return date.toLocaleDateString('fr-FR', options);
}
import React from 'react';
import { Scale, Radio, Car, Users, Star } from 'lucide-react';
import { NYPD_RULES } from '../data/rules';
import { Logo } from '../components/common/Logo';

const SECTION_ICONS = {
  oath: Star,
  values: Star,
  discipline: Scale,
  operations: Radio,
  equipment: Car,
  community: Users
};

export function Rules() {
  return (
    <div className="container mx-auto px-4 py-12">
      <div className="text-center mb-12">
        <Logo size="xl" className="mx-auto mb-4" />
        <h1 className="text-4xl md:text-5xl font-bold mb-4">Règlement du DPD</h1>
        <p className="text-gray-400 max-w-2xl mx-auto text-lg">
          Code d'honneur et de conduite du New York Police Department
        </p>
      </div>

      <div className="max-w-4xl mx-auto space-y-8">
        {Object.entries(NYPD_RULES).map(([key, section]) => {
          const IconComponent = SECTION_ICONS[key as keyof typeof SECTION_ICONS];
          return (
            <div key={key} className="bg-navy-800 rounded-lg overflow-hidden border border-gold-500/20">
              <div className="bg-navy-900/50 p-6 flex items-center space-x-4 border-b border-gold-500/20">
                <IconComponent className="w-8 h-8 text-gold-500" />
                <h2 className="text-2xl font-bold text-gold-500">{section.title}</h2>
              </div>
              <div className="p-6">
                <ul className="space-y-4">
                  {section.rules.map((rule, index) => (
                    <li key={index} className="flex items-start space-x-3">
                      <span className="text-gold-500 mt-1.5">•</span>
                      <span className="text-gray-300 leading-relaxed">{rule}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          );
        })}

        <div className="bg-navy-800/50 rounded-lg p-6 border border-gold-500/20 mt-8">
          <p className="text-gray-400 text-center italic">
            "En tant qu'officiers du DPD, nous nous engageons à maintenir les plus hauts standards 
            d'intégrité et de professionnalisme dans notre service à la ville de New York."
          </p>
        </div>
      </div>
    </div>
  );
}
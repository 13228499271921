import { useState, useEffect } from 'react';
import { DISCORD_CONFIG } from '../config/discord';
import { useDiscordServer } from './useDiscordServer';
import type { User } from '../types/auth';

export function useAuth() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState<User | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [token, setToken] = useState<string | null>(null);
  const { isServerMember, isChecking } = useDiscordServer(token);

  useEffect(() => {
    const storedToken = localStorage.getItem('discord_token');
    if (storedToken) {
      setToken(storedToken);
      fetchUser(storedToken);
    } else {
      const params = new URLSearchParams(window.location.hash.substring(1));
      const accessToken = params.get('access_token');
      
      if (accessToken) {
        localStorage.setItem('discord_token', accessToken);
        setToken(accessToken);
        window.history.replaceState({}, document.title, window.location.pathname);
        fetchUser(accessToken);
      } else {
        setIsLoading(false);
      }
    }
  }, []);

  const login = () => {
    const params = new URLSearchParams({
      client_id: DISCORD_CONFIG.clientId,
      redirect_uri: DISCORD_CONFIG.redirectUri,
      response_type: 'token',
      scope: 'identify guilds.members.read',
    });

    window.location.href = `https://discord.com/api/oauth2/authorize?${params}`;
  };

  const logout = () => {
    localStorage.removeItem('discord_token');
    setIsAuthenticated(false);
    setUser(null);
    setToken(null);
  };

  const fetchUser = async (token: string) => {
    try {
      setIsLoading(true);
      const response = await fetch('https://discord.com/api/users/@me', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      
      if (response.ok) {
        const userData = await response.json();
        setUser(userData);
        setIsAuthenticated(true);
      } else {
        localStorage.removeItem('discord_token');
        setIsAuthenticated(false);
        setUser(null);
        setToken(null);
      }
    } catch (error) {
      console.error('Failed to fetch user:', error);
      logout();
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isAuthenticated,
    user,
    login,
    logout,
    isLoading: isLoading || isChecking,
    isServerMember
  };
}
export interface Rank {
  id: string;
  title: string;
  description: string;
  responsibilities: string[];
  requirements?: string[];
  nextRanks?: string[];
  specialization?: boolean;
}

export const ranks: Record<string, Rank> = {
  cadet: {
    id: 'rookie',
    title: 'Rookie',
    description: 'Officier en formation à l\'académie de police.',
    responsibilities: [
      'Suivre la formation à l\'académie',
      'Apprendre les procédures et protocoles',
      'Participer aux exercices pratiques'
    ],
    nextRanks: ['officer_1']
  },
  officer_1: {
    id: 'officer_1',
    title: 'Officer I',
    description: 'Officier débutant ayant terminé sa formation initiale.',
    responsibilities: [
      'Patrouilles de routine',
      'Réponse aux appels',
      'Rédaction de rapports'
    ],
    requirements: [
      'Avoir complété la formation de Rookie',
      'Réussir l\'examen final de l\'académie'
    ],
    nextRanks: ['officer_2']
  },
  officer_2: {
    id: 'officer_2',
    title: 'Officer II',
    description: 'Officier expérimenté pouvant prendre des décisions sur le terrain.',
    responsibilities: [
      'Supervision d\'interventions mineures',
      'Formation des nouveaux officiers',
      'Gestion d\'incidents complexes'
    ],
    requirements: [
      'Évaluation positive des supérieurs'
    ],
    nextRanks: ['officer_3']
  },
  officer_3: {
    id: 'officer_3',
    title: 'Officer III',
    description: 'Officier senior avec accès aux spécialisations.',
    responsibilities: [
      'Supervision d\'équipes',
      'Gestion d\'opérations complexes',
      'Mentorat des officiers juniors'
    ],
    requirements: [
      'Formation spécialisée complétée'
    ],
    nextRanks: ['slo', 'sergeant']
  },
  slo: {
    id: 'slo',
    title: 'Officer III+I (Senior Lead Officer)',
    description: 'Officier III spécialisé dans la supervision terrain et le support aux équipes.',
    responsibilities: [
      'Supervision directe des patrouilles',
      'Formation et mentorat des officiers',
      'Coordination des opérations terrain',
      'Gestion des incidents complexes'
    ],
    requirements: [
      'Grade d\'Officier III',
      'Formation avancée en supervision',
      'Excellence dans le leadership terrain',
      'Recommandation des supérieurs'
    ],
    nextRanks: ['sergeant'],
    specialization: true
  },
  sergeant: {
    id: 'sergeant',
    title: 'Sergeant',
    description: 'Premier niveau de commandement, responsable d\'une équipe d\'officiers.',
    responsibilities: [
      'Supervision directe des officiers',
      'Coordination des patrouilles',
      'Évaluation des performances',
      'Formation continue des équipes'
    ],
    requirements: [
      'Grade d\'Officier III ou SLO',
      'Réussite de l\'examen de Sergent'
    ],
    nextRanks: ['sergeant_chief']
  },
  sergeant_chief: {
    id: 'sergeant_chief',
    title: 'Sergeant-Chief',
    description: 'Superviseur expérimenté coordonnant plusieurs équipes de patrouille.',
    responsibilities: [
      'Supervision des sergents',
      'Planification des opérations',
      'Gestion des ressources',
      'Coordination inter-services'
    ],
    requirements: [
      'Grade de Sergent',
      'Excellence dans le leadership'
    ],
    nextRanks: ['lieutenant_1']
  },
  lieutenant_1: {
    id: 'lieutenant_1',
    title: 'Lieutenant I',
    description: 'Officier supérieur responsable d\'une unité ou d\'un service.',
    responsibilities: [
      'Direction d\'une unité spécialisée',
      'Développement des stratégies opérationnelles',
      'Gestion administrative',
      'Liaison avec d\'autres départements'
    ],
    requirements: [
      'Grade de Sergent-Chef',
      'Formation avancée en gestion'
    ],
    nextRanks: ['lieutenant_2']
  },
  lieutenant_2: {
    id: 'lieutenant_2',
    title: 'Lieutenant II',
    description: 'Officier supérieur avec responsabilités étendues de commandement.',
    responsibilities: [
      'Supervision de plusieurs unités',
      'Élaboration des politiques',
      'Gestion budgétaire',
      'Représentation du département'
    ],
    requirements: [
      'Grade de Lieutenant I',
      'Excellence dans la gestion d\'unité'
    ]
  }
};
import React, { useState, useEffect } from 'react';
import { X } from 'lucide-react';

export function Disclaimer() {
  const [showDisclaimer, setShowDisclaimer] = useState(true);

  useEffect(() => {
    const hasSeenDisclaimer = localStorage.getItem('hasSeenDisclaimer');
    if (hasSeenDisclaimer) {
      setShowDisclaimer(false);
    }
  }, []);

  const handleDismiss = (permanent: boolean) => {
    setShowDisclaimer(false);
    if (permanent) {
      localStorage.setItem('hasSeenDisclaimer', 'true');
    }
  };

  if (!showDisclaimer) return null;

  return (
    <div className="fixed inset-0 z-50 bg-black/50 flex items-center justify-center p-4">
      <div className="bg-navy-800 rounded-lg p-6 max-w-lg w-full border border-gold-500/20">
        <div className="flex justify-between items-start mb-4">
          <h2 className="text-xl font-bold text-gold-500">Avertissement Important</h2>
          <button 
            onClick={() => handleDismiss(false)} 
            className="text-gray-400 hover:text-gray-300"
          >
            <X size={24} />
          </button>
        </div>
        <p className="text-gray-300 mb-6">
          Ce site est une simulation de recrutement pour le DPD. Il n'est en aucun cas affilié à un véritable 
          département de police. Toutes les informations et fonctionnalités présentes sont uniquement 
          destinées à des fins de roleplay.
        </p>
        <div className="flex justify-end space-x-4">
          <button
            onClick={() => handleDismiss(false)}
            className="px-4 py-2 text-gray-300 hover:text-white"
          >
            Fermer
          </button>
          <button
            onClick={() => handleDismiss(true)}
            className="px-4 py-2 bg-gold-500 text-navy-900 rounded hover:bg-gold-400"
          >
            Ne plus afficher
          </button>
        </div>
      </div>
    </div>
  );
}
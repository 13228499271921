import { useState, useEffect } from 'react';
import { DISCORD_CONFIG } from '../config/discord';

export function useDiscordServer(token: string | null) {
  const [isServerMember, setIsServerMember] = useState(false);
  const [isChecking, setIsChecking] = useState(true);

  useEffect(() => {
    const checkServerMembership = async () => {
      if (!token) {
        setIsChecking(false);
        return;
      }

      try {
        const response = await fetch(`https://discord.com/api/users/@me/guilds/${DISCORD_CONFIG.serverId}/member`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.ok) {
          setIsServerMember(true);
        } else if (response.status === 404) {
          // L'utilisateur n'est pas membre, on le redirige vers l'invitation
          window.location.href = DISCORD_CONFIG.serverInvite;
        }
      } catch (error) {
        console.error('Erreur lors de la vérification du serveur:', error);
      } finally {
        setIsChecking(false);
      }
    };

    checkServerMembership();
  }, [token]);

  return { isServerMember, isChecking };
}
import React from 'react';
import { Shield, Users, BadgeHelp, Scale } from 'lucide-react';

export function Home() {
  return (
    <div>
      <section className="relative min-h-[600px] flex items-center justify-center bg-navy-900">
        <div className="container mx-auto px-4 text-center">
          <Shield className="w-20 h-20 text-gold-500 mx-auto mb-6" />
          <h1 className="text-4xl md:text-6xl font-bold mb-4">
            District Police Department
          </h1>
          <p className="text-xl md:text-2xl text-gray-300 max-w-2xl mx-auto">
            Engagés à protéger et servir les citoyens du District
          </p>
        </div>
      </section>

      <section className="py-16 container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div className="bg-navy-800 p-8 rounded-lg border border-gold-500/20">
            <Users className="w-12 h-12 text-gold-500 mb-4" />
            <h3 className="text-xl font-bold mb-2">La Communauté d'Abord</h3>
            <p className="text-gray-400">
              Construire des relations solides avec nos communautés grâce à la confiance et la transparence.
            </p>
          </div>
          <div className="bg-navy-800 p-8 rounded-lg border border-gold-500/20">
            <BadgeHelp className="w-12 h-12 text-gold-500 mb-4" />
            <h3 className="text-xl font-bold mb-2">Service 24/7</h3>
            <p className="text-gray-400">
              Disponible jour et nuit pour répondre aux urgences et maintenir la sécurité de notre district.
            </p>
          </div>
          <div className="bg-navy-800 p-8 rounded-lg border border-gold-500/20">
            <Scale className="w-12 h-12 text-gold-500 mb-4" />
            <h3 className="text-xl font-bold mb-2">Justice & Intégrité</h3>
            <p className="text-gray-400">
              Faire respecter la loi avec équité, respect et une intégrité sans faille.
            </p>
          </div>
        </div>
      </section>
    </div>
  );
}